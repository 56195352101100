import React from "react";
import * as styles from "./styles.module.scss";
import BasePage from "../components/BasePage";
import SiteGrid from "../components/SiteGrid";
import SiteGutter from "../components/SiteGutter";
import Body from "../components/Body";

export default function Discontinued({ pageContext }) {
  const { body } = pageContext.slices;

  return (
    <BasePage pageContext={pageContext}>
      <div className={styles.container}>
        <SiteGutter>
          <SiteGrid>
            <Body richText={body} />
          </SiteGrid>
        </SiteGutter>
      </div>
    </BasePage>
  );
}
